import { useEffect, useState, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderTitle, setHeaderActionBtns, setHeaderArrowBack, setShowSearch } from 'src/plugins/redux/reducers/HeaderReducer';
import { fetchCompanyUsers, selectCompanyUsers, fetchCompany, selectCurrentCompany, deleteUser } from 'src/plugins/redux/reducers/AdminReducers';
import { useParams } from "react-router-dom";
import { EditUser } from 'src/plugins/redux/reducers/UsersReducers';
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import selectStyles from 'src/themes/Helpers';
import AddEditOperatorRepModal from './AddEditOperatorRepModal';
import toast from 'react-hot-toast';
import Tooltip from '@mui/material/Tooltip';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

const OperatorReps = forwardRef((props: any, ref: any): JSX.Element => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const { register, handleSubmit, watch, formState: { errors }, control, reset } = useForm();
    const company = useSelector(selectCurrentCompany);
    const customSelectStyles = selectStyles.formSelectStyles;
    const searchValue = useSelector((state) => state.header.search);
    const users = useSelector(selectCompanyUsers) || [];
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [userSelected, setUserSelected] = useState({});
    const [operatorRepModal, setOperatorRepModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);

    const handleShowDeleteModal = () => setDeleteModal(true);
    const handleCloseDeleteModal = () => setDeleteModal(false);

    const toggleOperatorRepModal = () => setOperatorRepModal(!operatorRepModal);


    useEffect(() => {
        dispatch(setHeaderTitle("Operator Representatives"));
    }, [users]);


    useEffect(() => {
        if (id) {
            dispatch(fetchCompany(id));
            dispatch(fetchCompanyUsers(id));
        }
        dispatch(setHeaderActionBtns(false));
        dispatch(setShowSearch(true));
        dispatch(setHeaderArrowBack(false));
    }, []);

    useEffect(() => {
        if (searchValue) {
            const filtered = users.filter((user) => {
                return user.first_name.toLowerCase().includes(searchValue.toLowerCase()) || user.last_name.toLowerCase().includes(searchValue.toLowerCase()) || user.email.toLowerCase().includes(searchValue.toLowerCase());
            });
            setFilteredUsers(filtered);
        } else {
            setFilteredUsers(users);
        }
    }, [searchValue, users]);

    const handleChange = async (user, val) => {
        const data = {
            company_id: user.company_id,
            id: user.id,
            role_id: user.role_id,
            first_name: user.first_name,
            last_name: user.last_name,
            active: val,
            is_test: val,
            email: user.email
        }
        const response = await dispatch(EditUser(data));
        if (response.payload?.success) {
            dispatch(fetchCompanyUsers(id));
            toast.success('User updated successfully');
        } else {
            toast.error('Error updating user');
        }
    }

    const handleChangeType = async (user, val) => {
        const data = {
            company_id: user.company_id,
            id: user.id,
            role_id: user.role_id,
            first_name: user.first_name,
            last_name: user.last_name,
            active: val,
            is_test: val,
            email: user.email
        }
        const response = await dispatch(EditUser(data));
        if (response.payload?.success) {
            dispatch(fetchCompanyUsers(id));
            toast.success("User updated successfully");
        } else {
            toast.error("Error updating user");
        }
    }

    const handleDeleteOperatorRep = async () => {
        const response = await dispatch(deleteUser(userSelected?.id));
        if (response.payload?.success) {
            dispatch(fetchCompanyUsers(id));
            toast.success("User deleted successfully");
            handleCloseDeleteModal();
        } else {
            toast.error("Error deleting user");
        }
    }



    return (
        <div>
            <div className="d-flex align-items-center justify-content-end">
                <button className="btn btn-outline-custom-primary rounded-pill" onClick={() => {
                    setUserSelected({})
                    toggleSupplierRepModal();
                }
                }>
                    Add a representative
                </button>
            </div>
            <div className="card mb-4 bg-custom-secondary rounded-pill mt-5">
                <div className="row p-5">
                    <div className="col-2 text-center text-white">Name</div>
                    <div className="col-2 text-center text-white">Email</div>
                    <div className="col-2 text-center text-white">Agreed to Terms on</div>
                    <div className="col-2 text-center text-white">Account Type</div>
                    <div className="col-2 text-center text-white">Active</div>
                    <div className="col-2 text-center text-white">Actions</div>
                </div>
            </div>
            {filteredUsers && filteredUsers.map((user) => {
                return (
                    <div className="card border-secondary mb-4 rounded-xl">
                        <div className="row p-5 align-items-center text-center">
                            <div className="col-2">
                                <div>{user.first_name + ' ' + user.last_name}</div>
                            </div>
                            <div className="col-2">
                                <div>{user.email}</div>
                            </div>
                            <div className="col-2">
                                <div>{user.agreed_at ? moment(user.agreed_at).format("YYYY-MM-DD HH:mm:ss") : "----"}</div>
                            </div>
                            <div className="col-2">
                                <div>
                                    <Select
                                        styles={customSelectStyles}
                                        defaultValue={{ value: user.is_test, label: user.is_test == 1 ? "Test Account" : "Real Account" }}
                                        options={[{ value: 0, label: "Real Account" }, { value: 1, label: "Test Account" }]}
                                        onChange={(val) => {
                                            handleChangeType(user, val.value)
                                        }}
                                        isSearchable={false}
                                    />
                                </div>
                            </div>
                            <div className="col-2">
                                <div>
                                    <Select
                                        styles={customSelectStyles}
                                        defaultValue={{ value: user.active, label: user.active == 1 ? "Active" : "NotActive" }}
                                        options={[{ value: 0, label: "NotActive" }, { value: 1, label: "Active" }]}
                                        onChange={(val) => {
                                            handleChange(user, val.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-2 d-flex align-items-center justify-content-center gap-2">
                                <Tooltip title="Edit Operator Rep" arrow>
                                    <i className="fa fa-edit text-primary-blue cursor-pointer" onClick={() => {
                                        setUserSelected(user);
                                        toggleOperatorRepModal();
                                    }}></i>
                                </Tooltip>
                                <Tooltip title="Delete Operator Rep" arrow>
                                    <i className="fa fa-times text-primary-blue cursor-pointer" onClick={() => {
                                        setUserSelected(user);
                                        handleShowDeleteModal();
                                    }}></i>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                );
            })}
            {company && filteredUsers.length == 0 &&
                <div className="text-center mt-5 d-flex align-items-center justify-content-center flex-column">
                    <h4 className="mb-2">No representatives found</h4>
                    <button className="btn btn-outline-custom-primary rounded-pill" onClick={() => {
                        setUserSelected({});
                        toggleOperatorRepModal();
                    }}>
                        Add a representative
                    </button>
                </div>}

            {!company &&
                <div className="text-center mt-5 d-flex align-items-center justify-content-center flex-column mt-5">
                    <h4 className="mb-2">No company found</h4>
                </div>
            }
            <Dialog
                open={deleteModal}
                onClose={handleCloseDeleteModal}>
                <DialogTitle>
                    Are you sure you want to delete this Rep?
                </DialogTitle>
                <div className="d-flex align-items-center justify-content-center gap-3 mb-3">
                    <button className="btn btn-outline-custom-primary btn-pill" onClick={handleCloseDeleteModal}>Close</button>
                    <button className="btn btn-danger btn-pill" onClick={handleDeleteOperatorRep}>Delete</button>
                </div>
            </Dialog>
            <AddEditOperatorRepModal toggle={toggleOperatorRepModal} isOpen={operatorRepModal} user={userSelected} company={company} />
        </div>
    );

});

export default OperatorReps;