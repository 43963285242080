import { useParams } from "react-router-dom";
import {
    fetchItem,
    selectCurrentItem,
} from "src/plugins/redux/reducers/RFPsReducer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
export default function RFPSupplierAssets() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const rfp = useSelector(selectCurrentItem);

    const [assets, setAssets] = useState([]);

    useEffect(() => {
        if (id) {
            dispatch(fetchItem({
                id: id
            }));
        }
    }, [id]);

    useEffect(() => {
        if (rfp) {
            const grouped = rfp?.supplier_assets?.reduce((acc, item) => {
                if (!acc[item.company_name]) {
                    acc[item.company_name] = [];
                }
                acc[item.company_name].push(item);
                return acc;
            }, {});
            console.log("🚀 ~ grouped ~ grouped:", grouped)

            setAssets(grouped);
        }
    }, [rfp]);

    const handleDownload = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = url.split('/').pop();
        link.click();

    }


    return (
        <div className="row">
            <div className="col-12 col-md-12">
                <div className="card mb-4 bg-custom-secondary rounded-pill">
                    <div className="row p-5">
                        <div className="col-2 col-md-2  text-center text-white">
                            Supplier
                        </div>
                        <div className="col-2 col-md-2 text-center text-white">
                            RFP Presentation
                        </div>
                        <div className="col-2 col-md-2 text-center text-white">
                            LTOs & Programming
                        </div>
                        <div className="col-2 col-md-2 text-center text-white">
                            Training Resources
                        </div>
                        <div className="col-1 col-md-1 text-center text-white">
                            Recipes
                        </div>
                        <div className="col-1 col-md-1 text-center text-white">Pricing Data</div>
                        <div className="col-1 col-md-1 text-center text-white">Sales Data</div>
                        <div className="col-1 col-md-1 text-center text-white">Trend Data</div>
                    </div>
                </div>

                {assets && Object.keys(assets)?.map((supplier) => {
                    const rfp_presentations = assets[supplier].find((item) => item.assets_type === "supplier_uploads_rfp_presentation");
                    const lto_programming = assets[supplier].find((item) => item.assets_type === "supplier_uploads_lto");
                    const training_resources = assets[supplier].find((item) => item.assets_type === "supplier_uploads_training_resources");
                    const recipes = assets[supplier].find((item) => item.assets_type === "supplier_uploads_recipes");
                    const pricing_data = assets[supplier].find((item) => item.assets_type === "supplier_uploads_pricing_data");
                    const sales_data = assets[supplier].find((item) => item.assets_type === "supplier_uploads_sales_data");
                    const trend_data = assets[supplier].find((item) => item.assets_type === "supplier_uploads_trend_data");
                    return (
                        <div style={{
                            maxHeight: "500px",
                            overflowX: "hidden",
                            overflowY: "auto",
                        }}>

                        <div
                            className="row p-5 align-items-center mb-4 bg-white border border-secondary rounded-lg">
                            <div className="col-2 col-md-2 text-center">
                                {supplier}
                            </div>
                            <div className=" col-2 d-flex align-items-center text-dark justify-content-center gap-1">
                                {rfp_presentations ? <>
                                    <a href={rfp_presentations?.asset_file} target="_blank">
                                        <i className="fa fa-eye text-primary"></i>
                                    </a>
                                    <a className="cursor-pointer" onClick={() => {
                                        handleDownload(rfp_presentations?.asset_file)
                                    }}>
                                        <i className="fa fa-download text-primary"></i>
                                    </a>
                                </> : '-'}
                            </div>
                            <div className="col-2 d-flex align-items-center gap-1 justify-content-center">
                                {lto_programming ? <>
                                    <a href={lto_programming?.asset_file} target="_blank">
                                        <i className="fa fa-eye text-primary"></i>
                                    </a>
                                    <a className="cursor-pointer" onClick={() => {
                                        handleDownload(lto_programming?.asset_file)
                                    }}>
                                        <i className="fa fa-download text-primary"></i>
                                    </a>
                                </> : '-'}
                            </div>

                            <div className="col-2 d-flex align-items-center gap-1 justify-content-center">
                                {training_resources ? <>
                                    <a href={training_resources?.asset_file} target="_blank">
                                        <i className="fa fa-eye text-primary"></i>
                                    </a>
                                    <a className="cursor-pointer" onClick={() => {
                                        handleDownload(training_resources?.asset_file)
                                    }}>
                                        <i className="fa fa-download text-primary"></i>
                                    </a>
                                </> : '-'}
                            </div>
                            <div className="col-1 d-flex align-items-center gap-1 justify-content-center">
                                {recipes ? <>
                                    <a href={recipes?.asset_file} target="_blank">
                                        <i className="fa fa-eye text-primary"></i>
                                    </a>
                                    <a href={recipes?.asset_file} download target="_blank">
                                        <i className="fa fa-download text-primary"></i>
                                    </a>
                                </> : '-'}
                            </div>
                            <div className="col-1 d-flex align-items-center gap-1 justify-content-center">
                                {pricing_data ? <>
                                    <a href={pricing_data?.asset_file} target="_blank">
                                        <i className="fa fa-eye text-primary"></i>
                                    </a>
                                    <a className="cursor-pointer" onClick={() => {
                                        handleDownload(pricing_data?.asset_file)
                                    }}>
                                        <i className="fa fa-download text-primary"></i>
                                    </a>
                                </> : '-'}
                            </div>
                            <div className="col-1 d-flex align-items-center gap-1 justify-content-center">
                                {sales_data ? <>
                                    <a href={sales_data?.asset_file} target="_blank">
                                        <i className="fa fa-eye text-primary"></i>
                                    </a>
                                    <a className="cursor-pointer" onClick={() => {
                                        handleDownload(sales_data?.asset_file)
                                    }}>
                                        <i className="fa fa-download text-primary"></i>
                                    </a>
                                </> : '-'}
                            </div>
                            <div className="col-1 d-flex align-items-center gap-1 justify-content-center">
                                {trend_data ? <>
                                    <a href={trend_data?.asset_file} target="_blank">
                                        <i className="fa fa-eye text-primary"></i>
                                    </a>
                                    <a className="cursor-pointer" onClick={() => {
                                        handleDownload(trend_data?.asset_file)
                                    }}>
                                        <i className="fa fa-download text-primary"></i>
                                    </a>
                                </> : '-'}
                            </div>
                        </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}